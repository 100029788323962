// noinspection ES6ConvertVarToLetConst

/**
 * @returns {boolean}
 */
function urlMatches() {
  return (
    location.pathname.startsWith("/rooms") &&
    !location.pathname.includes("/select")
  );
}

function handleAgreeCheck(agreeBtn, notShowBtn, checked, updatedAt) {
  agreeBtn.prop("disabled", !checked);
  notShowBtn.prop("disabled", !checked);

  checked
    ? localStorage.setItem("popup_checked", updatedAt)
    : localStorage.removeItem("popup_checked");
}

$(function () {
  if (window.is_logged_in) {
    $.ajax({
      url: "/popups/active",
      method: "get",
      dataType: "json",
      success: function (res) {
        var viewed = localStorage.getItem("popup_accepted") || "";
        var checked = localStorage.getItem("popup_checked") || "";
        if (
          (window.just_logged_in || urlMatches()) &&
          res.updated_at !== viewed
        ) {
          var modal = $("#popup-modal");
          var notShowBtn = modal.find(".donot-show-again");
          var iAgree = modal.find(".i-agree");
          var agreeBtn = modal.find(".btn-agree");

          modal.find("#popup-modal-content").html(res.content);

          if (checked != null && checked === res.updated_at) {
            iAgree.find("input").prop("checked", true);
            handleAgreeCheck(agreeBtn, notShowBtn, true, res.updated_at);
          }

          if (res.important) {
            modal.removeAttr("data-micromodal-close");
            iAgree.css("display", "flex");
            agreeBtn.css("display", "block");

            iAgree.find("input").on("change", function () {
              var checked = $(this).is(":checked");
              handleAgreeCheck(agreeBtn, notShowBtn, checked, res.updated_at);
            });
          } else {
            notShowBtn.prop("disabled", false);
          }

          notShowBtn.click(function () {
            localStorage.setItem("popup_accepted", res.updated_at);
          });

          MicroModal.show("popup-modal");
        }
      },
    });

    $(".logout-link").click(function () {
      localStorage.removeItem("popup_accepted");
      localStorage.removeItem("popup_checked");
    });
  }
});
